//定义路由
export default [
    {
        name: 'metadata',
        path: '/metadata',
        meta: {requireAuth: true, roles: ['M']},
        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/mainPage.vue'),
        redirect: "/metadata/data",
        children: [
            {
                path: '/metadata/data',
                meta: {requireAuth: true, roles: ['M']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/originalData.vue'),
            },
            {
                path: '/metadata/dataparsing',
                meta: {requireAuth: true, roles: ['M']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/dataParsing.vue'),
            },
            {
                path: '/metadata/datasend',
                meta: {requireAuth: true, roles: ['M']},
                component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/dataSend.vue'),
                redirect: "/metadata/datasend/readData",
                children: [
                    {
                        path: '/metadata/datasend/readData',
                        meta: {requireAuth: true, roles: ['M']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'readData', sn: route.query.sn}),
                    },
                    {
                        path: '/metadata/datasend/writeData',
                        meta: {requireAuth: true, roles: ['M']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'writeData'}),
                    },
                    {
                        path: '/metadata/datasend/setStatus',
                        meta: {requireAuth: true, roles: ['M']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'status'}),
                    },
                    {
                        path: '/metadata/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['M']},
                        component: () => import(/* webpackChunkName: "metadata" */ '@/views/metadata/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                ]
            },
        ]
    }
]
