/*jshint esversion:6 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import router4metadata from './router4metadata';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

//解决在当前页面点击当前页面链接报错的问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
//定义路由
const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        component: () => import('@/views/login/login.vue')
    },
    ...router4metadata,
    {
        path: "*",
        redirect: "/404",
    },
    {
        path: "/404",
        component: resolve => require.ensure([], () => resolve(require('@/views/notFound.vue')), 'forgot'),
    },
];

//创建router实例, 然后传routes配置
const router = new VueRouter({routes});

sessionStorage.getItem('token') ? store.commit('set_token', {token: sessionStorage.getItem('token'), status: true}) : null;
store.commit('set_serverKey', sessionStorage.getItem('serverKey'));
store.commit('set_key', {pkcsType: 'pkcs8', pkcsSize: '1024'});//生成公私钥

//页面跳转之前判定是否需要处于登录状态
router.beforeEach((to, from, next) => {
    store.commit('CLAER_CANCEL'); // 取消请求

    if (to.matched.some(record => record.meta.requireAuth)) {
        //这里的requireAuth为路由中定义的 meta:{requireAuth:true}，意思为：该路由添加该字段，表示进入该路由需要登陆的
        if (store.state.token.token) {
            let roles = to.meta.roles;
            let types = jwtDecode(store.state.token.token).userType;
            if (roles) {
                let flag = false
                for (let i = 0; i < types.length; i++) {
                    if (roles.includes(types[i])) {
                        flag = true
                        break;
                    }
                }
                if (flag) {
                    next();
                } else {
                    next({path: "/404"});
                }
            } else {
                next({path: "/404"});
            }
        } else {
            next({path: '/login', query: {redirect: to.fullPath}})
        }
    } else {
        next();
    }
});

export default router;
